.ourReach{
  margin-top: 15vh;
}
.ourReachText{
white-space: nowrap;
}

.ourReachCont{
    /* padding: 10px 0px; */
    display: flex;
    /* background: #353b48; */
   
}
.moving-text{
    display: flex;
    align-items: center;
}
.moving-text:hover{
    animation-play-state: paused;
}


.ourReachImgCont{
    display: flex;
    width: 100%;
    /* justify-content: space-around; */
    margin-bottom: 5px;
}
.ourReachImg{
    margin-left: 30px;
}
.ourReachImgCont img{
    /* height: 45px; */
    /* height: 10vh; */
    height: 10vh;
    aspect-ratio: 2/2;
    /* border: 1px solid var(--headColor); */
    border-radius: 50%;
}
.reachContHead{
    width: 100%;
    text-align: center;
    color: white;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

/* The animation */
@keyframes marquee{
    0%{transform: translateX(100%);}
    100%{transform: translateX(-100%);}
}

/* media query to enable animation for only those who want it */
@media (prefers-reduced-motion: no-preference) {
    .moving-text{
        animation: marquee 30s linear infinite;
    }
   
}

@media screen and (max-width:700px){
    .ourReachImgCont img{
        height: 5vh;
    }
    .ourReach{
        margin-top:50px;
    }
}


