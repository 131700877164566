.wcu {
  min-height: 100vh;
  padding-top: 50px;
  background: var(--wcuBack);
  padding: 50px 140px;
  color: var(--headColor);
  padding-top:80px ;
}

.wcuCont{
  transform: scale(0.9);
}

.wcuHead {
  background: var(--baseColor);
  font-weight: bolder;
  font-family: "Monomaniac One", sans-serif;
  /* font-size: 45px; */
  font-size: 3.5vw;
  width: 30vw;
  margin-left: -60px;
  font-style: italic;
  color: white;
  white-space: nowrap;
  display: flex;
  z-index: 1;
  position: relative;
  align-items: center;
  justify-content: center;

}

.wcuContentDiv {
  /* border: 2px solid black; */
  height: calc(90vh - 50px);
  display: flex;
  justify-content: space-around;
}

.wcuCard {
  /* border: 2px solid black; */
  height: 100%;
  width: 300px;
  width: 20vw;
}
.wcuCard:nth-child(1) {
  /* border: 2px solid black; */
  padding-top: 10px;
  
}

.wcuCard:nth-child(2) {

  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.45) 2.4px 2.4px 3.2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-top: 150px; */
  /* margin-top: -60px; */
}
.wcuCard:nth-child(3) {
  display: flex;
  flex-direction: column;
  justify-content: end;
 
}
.wcuCardHead {
  /* font-size: 28px; */
  font-size: 2.5vw;
  text-align: right;
  padding: 0px 20px;
  font-style: italic;
  color: var(--baseColor);
  font-weight: bolder;
  font-family: Jaturat SemiBold;
  font-family: "Monomaniac One", sans-serif;
  line-height: 1;
}

.wcuCardContent {
  /* font-family: Clear Sans Regular; */
  text-align: right;
  padding: 10px;
  font-size: 13px;
  font-size: 1vw;
  /* text-align: justify;
    hyphens: auto; */
}

.wcu .colorDivCont {
  display: flex;
  justify-content: right;
  padding: 0 20px;
}
.wcu .colorDivCont div:nth-child(1) {
  height: 30px;
  width: 20px;
  background: var(--baseColor);
  margin: 5px;
  transform: skew(-20deg);
  border-radius: 5px;
  opacity: 0.7;
}
.wcu .colorDivCont div:nth-child(2) {
  height: 30px;
  width: 20px;
  margin: 5px;
  transform: skew(-20deg);

  background: #818286;
  border-radius: 5px;
  opacity: 0.7;
}
.wcu .colorDivCont div:nth-child(3) {
  height: 30px;
  width: 20px;
  margin: 5px;
  transform: skew(-20deg);
  background: #818286;
  border-radius: 5px;
  opacity: 0.7;
}


@media screen and (max-width:700px) {
  .wcuHead{
    margin-left: 10px;
    margin-bottom: 50px;
    font-size: 30px;
    width: 300px;
  }
  .wcuCardHead{
    font-size: 15px;
  }
  .wcuCardContent{
    font-size: 10px;
  }
  .wcuCard {
    /* border: 2px solid black; */
    height: 100%;
    width: 33vw;
  }
  .wcuContentDiv{
    height: auto;
  }
  .wcu{
    min-height: 0vh;
  }
  .wcuCard:nth-child(2) {
    /* margin-top: 20px; */
    padding: 70px 0px;
  }
  .wcuCard:nth-child(3) {
    /* display: revert; */
    padding-top: 150px;
  }

  .smallText{
    /* width: 300px; */
    /* display: none; */
    font-size: 9px;
  }
  .wcu{
    padding: 50px 0px;
  }

  .wcuCont{
    transform: scale(1);
  }


}

@media screen and (max-width:900px) and (orientation: landscape) {
  .wcu{
    padding: 0;
  }
  .wcuContentDiv {
    /* border: 2px solid black; */
    height: auto;
    display: flex;
    justify-content: space-around;
  }

    .wcuHead {
    font-size: 25px;
    width: 210px;
    margin-left: 0px;
  }

  .wcuCard:nth-child(3) {
    padding-top: 65px;
}
.wcuCard:nth-child(2) {
  padding-top: 117px;
  margin-top: -60px;
  padding-bottom: 97px;
}
  
}