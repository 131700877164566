.rfq{
    min-height: 100vh;
    color: var(--headBack);
    background: var(--rfqBack);
    background-size: cover;
    padding: 50px 150px;
    color: var(--headColor);
    padding: 5vh 150px;
}

.rfqCont{
    /* transform: scale(0.85); */
}
.rfq .textDiv{
    position: relative;
    font-size: 32px;
    font-size: 2.5vw;
    font-weight: bolder;
    white-space: nowrap;
    margin-left: 20px;
    color:var(--headColor);

}
.rfq .textDiv div:nth-child(1) {
    background: var(--baseColor);
    height: 30px;
    left: -5px;
    bottom: -10px;
    width: 20vw;
    position: absolute;
    z-index: 0;
}

.rfq .textDiv div:nth-child(2) {
    z-index: 1;
}
.rfqCardCont{
    margin-top: 5vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.rfqCard{
    position: relative;
    text-align: center;
    border: 2px solid var(--baseColor);
    width: 20vw;
    height: 45vh;
    margin-top: 20px;
    font-size: 1.2vw;
}

.rfqBtn{
    background-color: var(--baseColor);
    font-weight: bolder;
    color: #023681;
    padding: 15px;
    position: absolute;
    width: 100%;
    bottom: 0;
    cursor: pointer;
}

.rfqBtn a{
    text-decoration: none;
    color: #023681;
    font-size: 18px;
    font-size: 1.2vw;
}
.rfqCardHead{
    padding: 15px;
    font-weight: bolder;
    font-size: 18px;
    font-size: 1.2vw;
    /* color: white; */ 
    /* text-decoration: underline; */
    /* text-decoration-color:var(--baseColor); */
    /* text-decoration-thickness: 3px; */
}
.rfqCardContent{
    padding: 15px;
}

@media screen and (max-width:700px) {
    .rfqHead{
        text-align: center;
        margin:0;
        font-size: 25px;
    }
    .rfqCardCont{
        margin-top: 10px;
    }
    .rfq .textDiv{
      margin-left: 20px;
      justify-content: center;
      font-size: 23px;
      white-space: wrap;
    }

    .rfq .textDiv div:nth-child(1) {
        background: var(--baseColor);
        height: 25px;
        left: 20px;
        bottom: -10px;
        width: 230px;
    }
    .rfq{
        padding: 0;
    }
    .rfqCont{
        transform: scale(1);
    }
    .rfqCard{
        width: 300px;
        height: 300px;
    }
    .rfqCardHead,.rfqCardContent,.rfqBtn a{
        font-size: 18px;
    }
    
}


@media screen and (max-width:900px) and (orientation: landscape) {
    .rfq{
     padding: 0;
    }

    .rfqCardCont{
        margin-top: 10px;
      
    }
    
    .rfqCard{
        position: relative;
        text-align: center;
        border: 2px solid var(--baseColor);
        width: 28vw;
        height: auto;
        margin-top: 20px;
        height: 180px;
    }
    
 }