.career{
    padding-top: 50px;
    background: white;
    color: var(--headColor);
}
.carFirstPage{
    height: calc(100vh - 50px);
    background: url("../images/cp3.png");
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-bottom: 50px;
    padding-left: 50px;
    color: white;
}
.carFirstPage .starDiv i{
    color: #e1aa35;
    font-size: 25px;
    font-size: 2.5vw;
    margin: 15px 0px;
}
.carFirstPage .posBtn{
    background: #e1aa35;
    color: white;
    width: 200px;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    font-size: 20px;
    margin: 25px 0px;
    cursor: pointer;
    position: relative;
    transition: all 0.5s;
}

.carFirstPage .contentCont{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /* border: 2px solid black; */
    width: 390px;
    width: 35vw;

}

.contentCont .div1{
    /* font-size: 32px; */
    font-weight: bolder;
    margin-bottom: 30px;
    font-size: 3.2vw;
}

.contentCont .div2{
    font-size: 18px;
    margin-bottom: 10px;
    font-size: 1.5vw;
}

.contentCont .careerCont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.carSecondPage {
    height: calc(100vh);
}

.carSecondPage .textDiv{
    height: 70%;
    background: url("../images/cp2.png");
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.carSecondPage .textDiv div:nth-child(1){
    /* font-size: 32px; */
    font-size: 3vw;
    margin-bottom: 20px;
}
.carSecondPage .textDiv div:nth-child(2), .carSecondPage .textDiv div:nth-child(3){
    /* font-size: 18px; */
    font-size: 1.2vw;
    margin-bottom: 20px;

}

.carSecondPage .imgDiv{
    /* border: 2px solid black; */
    display: flex;
    height: 30%;
    justify-content: center;
}
.imgDiv img{
    height: 100%;
}

.carFirstPage .openPositions{
    position: absolute;
    width: 400px;
    font-size: 16px;
    line-height: 1.8;
    text-align: center;
    transition: all 0.5s;
    height: 0px;
    overflow: hidden;
    top: -205px;
    left: -100px;
border-radius: 15px;
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(15px) contrast(0.8);
-webkit-backdrop-filter: blur(15px) contrast(0.8);
font-weight: 500;
filter: blur(0.4px);
-webkit-filter: blur(0.4px);
transform: translate3d(0, 0, 0); 
color: white;
}

.joinUsBtn{
    color: white;
    text-decoration: none;
    font-size: 16px;
    padding: 10px;
    background: #e1aa35;
    border-radius: 5px;
}

/* .carFirstPage .posBtn:hover .openPositions{
    height: 200px;
} */

@media screen and (max-width:700px) {
    .carFirstPage{
        padding: 0;
        padding-bottom: 100px;

    }
    .carSecondPage .imgDiv{
        height: 15%;
    }   
    
    .carSecondPage .textDiv{
        height: 85%;
        /* color: var(--headColor); */
        /* background: #959ea9; */
        padding: 0 30px;
        color: black;
    }
    .carFirstPage .openPositions{
        width: 300px;
        left: -55px;
    }
    #rc-anchor-container{
        z-index: -1;
    }
    .carSecondPage .textDiv div:nth-child(1){
        font-size: 32px;
    }
    .carSecondPage .textDiv div:nth-child(2), .carSecondPage .textDiv div:nth-child(3){
        font-size: 18px;
    }
    .carFirstPage .starDiv i{
        font-size: 25px;
    }
    .contentCont .div1{
        font-size: 32px;
    }
    .carFirstPage .contentCont{
        width: 390px;
    }
    .contentCont .div2 {
        font-size: 18px;
    }
}

@media screen and (max-width:900px) and (orientation: landscape) {

    .carFirstPage{
        height: 400px;
    }

    
}