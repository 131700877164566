.panel{
    padding-top: 50px;
    min-height: 100vh;
    background: var(--panelBack);
    padding: 80px 50px;
    color: var(--headColor);
}
.panelLeft{
    width: 50%;
    padding: 50px;
    /* font-size: 20px; */
    text-align: justify;
    hyphens: auto;
    font-size: 1.6vw;
}
.panelRight{
    width: 50%;
}

.panelRight .panelImgCont{
    position: relative;
    /* border: 2px solid black; */
}

.panelRight .panelImgCont img{
   width: 100%;
}
.panelRight .panelImgCont .orangeStrip{
    width: 250px;
    height: 40px;
    background-color: var(--baseColor);
    position: absolute;
    bottom: 1%;
    left: -4%;
}

.panelHeadDiv .panelHead{
    font-size: 45px;
    font-size: 3.5vw;
    font-weight: bolder;
    text-align: center;
}
.comingSoonDiv{
    justify-content: center !important;
    align-items: center;
    font-weight: bolder;
}
.comingSoonDiv img{
    width: 200px;
    margin-right: 20px;
    margin-top: 30px;
}
.panel .collab{
text-align: center;
font-size: 20px;
font-weight: bold;
font-size: 1.8vw;
}
.panel .contactText{
text-align: center;
font-style: italic;
margin-top: 10px;
font-size: 1.2vw;
}

.panelHeadDiv{
    position: relative;
}
.panelHeadDiv .leftBorder{
    height: 80%;
    width: 10px;
    margin-top: 5%;
    position: absolute;
    left: -10px;
}

.panelHeadDiv .leftBorder div:nth-child(1){
    height: 50%;
    background: var(--baseColor);
}
.panelHeadDiv .leftBorder div:nth-child(2){
    height: 50%;
    background: var(--headColor);
}


@media screen and (max-width:700px) {
    .panelCont{
        flex-direction: column-reverse;
        padding-top: 20px;
    }
    .panelLeft,.panelRight{
        width:100%;
        padding: 0;
        /* padding-left: 20px; */
    }
    .panelLeft{
        margin-top: 70px;
        font-size: 20px;
    }
    .panel{
        padding: 80px 40px;
    }
    .panelHeadDiv .panelHead{
        font-size: 35px;
    }
    .panel .collab{
        font-size: 20px;
    }
    .panel .contactText{
        font-size: 15px;
    }
}

@media screen and (max-width:900px) and (orientation: landscape) {
    .panel{
        padding: 0;
    }
}
