@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monomaniac+One&family=Roboto&display=swap');

*{
    font-family: Roboto;
    box-sizing: border-box;
  
  }
  html{
    scroll-behavior: smooth;
  }
  body{
  padding: 0;
  margin: 0;
  /* background: black; */
  /* overflow: hidden; */
  }
  #root{
    overflow: hidden;
  }
  
  :root{
    --baseColor:#ff9000;;
    --navBackColorOld:#033D43;
    --navBackColor:white;
    --homeBack:url("../images/back/homeback.png");
    /* --servicesBack:url("../images/back/back9.png"); */
    --servicesBack:#f7f7f7;
    /* --rfqBack:url("../images/back/back7.png"); */
    --rfqBack:#f7f7f7;

    --wcuBack:#f4f0ef;;
    --aboutUsBack:white;
    --footerBack:	url("../images/back/footerBack.png");
    --teamBack:	white;
    --panelBack:white;
    --spBack:white;
    --dpBack:white;
    --proManBack:white;
    --contactBack:white;
    --careerBack:url("../images/cp3.png")
    /* --quote:burlywood;  */
    --quote:linear-gradient(to right, #079bad, #00abab, #00ba9c, #2cc684, #6dd063) !important; 
    --proManBack:white;
    --headColor:#5d676a;

  }

  .flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
 
  
  @keyframes zoom {
    0%{
        transform: scale(0.1);
    }
    100%{
        transform: scale(1);
    }
}
  
  @import url('./navbar.css');
  @import url('./home.css');
  @import url('./services.css');
  @import url('./team.css');
  @import url('./aboutUs.css');
  @import url('./rfq.css');
  @import url('./footer.css');
  @import url('./reach.css');
  @import url('./surveyProgramming.css');
  @import url('./dataProcessing.css');
  @import url('./panel.css');
  @import url('./contactUs.css');
  @import url('./wcu.css');
  @import url('./loader.css');
  @import url('./popUp.css');
  @import url('./notFound.css');
  @import url('./projectManagement.css');
  @import url('./career.css');
  @import url('./testimonial.css');
  @import url('./modal.css');
  

  @media screen and (max-device-width: 480px){
    html{
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none !important;
  text-size-adjust: none !important;
    }
    body{
      -moz-text-size-adjust: none;
      -webkit-text-size-adjust: none !important;
      text-size-adjust: none !important;
        }
  }
  
  
  