.dataProcessing{
    min-height: 100vh;
    /* padding-top: 50px; */
    background: var(--dpBack);
    color: var(--headColor);
    font-size: 1.2vw;
}

.dataProcessing .dpCont{
    /* padding-bottom: 30px; */
    padding: 50px 60px;
    min-height: calc(100vh - 50px);
}
.dataProcessing .dpLeft{
    width: 50%;
    padding: 20px;
    text-align: justify;
    hyphens: auto;
    /* border: 2px solid black; */

}
.dataProcessing .dpRight{
    width: 50%;
    display: flex;
    flex-direction: column;
   justify-content: center; 
    align-items: center;
    /* padding-bottom: 200px; */
}
.dataProcessing .dpRight img{
    width: 70%;
    border-radius: 10px;
}

.dataGifCont .imgCont{
    /* border: 2px solid black; */
    display: flex;
    justify-content: end;
}
.dataProcessing .textDiv{
    position: relative;
    font-weight: bolder;
    margin-top: 10px;
    margin-bottom: 25px;
    font-size: 2.5vw;

}

.dataProcessing .textDiv.dataAnalysis{
    width: 50%;
}

.dataProcessing .textDiv div:nth-child(1) {
    background: var(--baseColor);
    height: 80%;
    left: 10%;
    bottom: -30%;
    width: 80%;
    position: absolute;
    z-index: 0;
}

.dataProcessing .textDiv div:nth-child(2) {
    z-index: 1;
}
.dataProcessing .dpSubHead{
    font-style: italic;
    font-weight: bolder;
    justify-content: center;
    margin-top: 30px;
}
.dpSubHead img{
    height: 50px;
    margin-right: 20px;
    z-index: 1;
}
.dpSubHead{
    margin-bottom: 20px;
    /* border:2px solid black; */
    /* width: 200px; */
    margin-left: 70px;
    position: relative;
    justify-content: left !important;
}

.dpSubHead .orangeCircle{
    background: var(--baseColor);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    left: -2%;
    top: -30%;

}

.dpCont:nth-child(2){
    background: #ecf0f1;
}

.dataProcessing .subHead{
    font-weight: bolder;
}

.dataProcessing .dpCont .dataGifCont{
    display: flex;
    height: 100%;
    justify-content: center !important;
    /* border: 2px solid black; */
}
.dataProcessing .dpCont .dataGifCont img{
    max-height: 85%;
    max-width: 92%;
    border-radius:10px;
}
.dataGifContText{
position: relative;
height: 100% !important;
/* font-size: 18px; */
font-size: 1.6vw;
width: 50%;
}

.orangeDots{
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    width: 90px;
  right: 0%;
    top: -4%;
}
.orangeDots div{
    height: 10px;
    width: 10px;
    margin: 10px;
    border-radius: 50%;
    background: var(--baseColor);
}

.dataProcessing .advance{
    height: calc(100vh - 50px);
    /* border: 2px solid black; */
}

.dpCont.advance{
    /* border: 2px solid black; */
    display: flex;
    justify-content: start;
    align-items: start;
}
.dpCont.advance .advanceText{
    /* border: 2px solid black; */
    height: 90%;
    width: 50%;
    /* position: relative; */
    background: #2c92d5;
}

.dpCont.advance .advanceImgDiv{
    /* border: 2px solid black; */
    height: 100%;
    width: 50%;
}
.dpCont.advance .advanceImgDiv img{
  max-width: 100%;
  max-height: 100%;
}


.dpCont.advance .rfd{
    width: 100%;
    height: 20%;
    /* border: 2px solid black; */
    color:#2c92d5;
    background: #cfe4f5;
    font-weight: bolder;
    display: flex;
    justify-content: left;
    padding-left: 30px;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    
}

.dpCont.advance .advanceTextDiv{
height: 80%;
color: white;
font-size: 80px;
font-weight: bolder;
text-align: center;
}

.dpCont.charts{
    height: calc(100vh - 50px);
    width: 100%;
    background:#f6f6f6;
    /* border: 2px solid black; */
}
.dpCont.charts .chartImg{
    height: 100%;
}
.dpCont.charts .chartImg img{
    max-width: 100%;
    max-height: 100%;
    border-bottom: var(--baseColor) 10px solid;
}
.dpCont.charts .chartText{
    background: #f6f6f6;
    /* height: 100%; */
    padding: 20px 30px;
}
.dpCont.charts .chartText h1{
    font-size: 3vw;
}

.dpCont.charts .chartText h3{
    color: var(--baseColor);
    font-size: 2.5vw;
}

@media screen and (max-width:700px) {
    .dataProcessing .dpCont.dpCont1,.dataProcessing .dpCont.dpCont2{
        flex-direction: column-reverse;
        padding:50px 20px;
    }
    .dataProcessing .dpRight img{
        width: 100%;
    }
    .dataProcessing .dpRight{
        width: 100%;
        margin-bottom: 20px;
    }
    .dataProcessing .dpLeft{
        width: 100%;
        padding: 20px;
        text-align: justify;
        hyphens: auto;
        /* border: 2px solid black; */
    
    }
    .dpSubHead{
        margin-left: 10px;
    }

    .dataProcessing .toolsList{
        display: block;
    }

    .dataProcessing .dpCont .dataGifCont{
        flex-direction: column-reverse;
    }
    .dataGifContText{
        width: 100%;
    }
    
    .dpCont.charts{
       flex-direction: column;
       height: auto;
        /* border: 2px solid black; */
    }
    .dpCont.charts .chartText{
        background: #f6f6f6;
        /* height: 100%; */
        padding: 10px 30px;
    }

    .dpCont.advance{
        flex-direction: column;
    }
    .advanceText{
        width: 90vw !important;
        height: 280px !important;
        font-size:15px;
    }
    .advanceTextDiv{
        font-size: 40px !important;
    }
    .advanceImgDiv{
        width: 98vw !important;
        margin-top: 50px;
    }
    .dpCont.advance{
        height: auto;
    }
    .chartText{
        width: 95vw;
    }
    
    .chartImg{
        width: 90vw;
    }

    .dataProcessing .dpCont.dpCont3 .dataGifCont{
        flex-direction:column !important;
    }
    .dataGifCont .imgCont img{
        max-width: 100% !important;
        max-height: 100% !important;
    }


    .orangeDots{
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        width: 90px;
        right: 5%;
        left: revert;
    }

    .dataProcessing .dpCont{
        padding: 0 10px;
    }
    .dataProcessing{
        font-size: 16px;
        
    }
    .dataGifContText{
        font-size: 18px;
    }
    .dpCont.charts .chartText h3{
        font-size:18px;
    }
    .dpCont.charts .chartText h1{
        font-size: 33px;
    }

    .dataProcessing .textDiv{
        font-size: 7vw;
    }

    .dataProcessing .textDiv.dataAnalysis{
        width: 80%;
    }
   

}


@media screen and (max-width:900px) and (orientation: landscape) {
    .dataProcessing .dpCont{
        padding:15px 10px;
    }
    .dpCont.charts{
        height: auto;
     }
     .dpCont.charts img{
        max-height: 400px !important;
     }
     .dataProcessing{
        padding-top: 20px;
     }
     .orangeDots{
        width: 130px;
     }
  

}