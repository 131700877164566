.modal {
  height: calc(100vh);
  width: 100%;
  /* border: 2px solid black; */
  position: fixed;
  top: 0px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: white; */
  z-index: 2000;
  /* display: none; */
  transition: all 0.5s;
  transform: scale(0);
  background: rgba(255, 255, 255, 0.37);
}

.modal .card {
  width: 400px;
  height: 200px;

  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */

  background: rgba(255, 255, 255, 0.2);
  border-radius: 7px;

  background: white;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

}

.modal .cardText {

  height: 60%;
  width: 80%;
  text-align: center;
  color: var(--headColor);
}

.modal .cardText div:nth-child(1){
  font-weight: bold;
  font-size: 20px;
  margin: 20px 0;

}
.modal .card .tick{
  background-color: var(--navBackColorOld);
  /* background: #4cd137; */
  /* padding: 10px 15px; */
  border-radius: 50%;
  color: white;
  font-size: 30px;
  margin-top: -30px;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal .card .cardBtn{
  width: 50%;
  background-color: var(--navBackColorOld);
/* background: #4cd137; */
  color: white;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
}

@media screen and (max-width: 800px) {
  .modal .card {
    width: 80vw;
  }
}
