.footer{
    height: 270px;
    /* border: 2px solid black; */
    background:#2d3436;
    color: white;
    display: flex;
    /* justify-content: flex-end; */
    padding: 10px 50px;
}
.leftFoot{
    width: 80%;
    /* border: 2px solid black; */
}
.rightFoot{
  
    border-left: 2px solid white;
  
    padding-left: 10px;
    height: 85%;
    margin-top: 2%;

}
.footerLogoLink{
text-decoration: none;
color: white;
}

.footer .logoCont{
    display: flex;
    align-items: center;
    font-size: 14px;
}
.footer .logoCont img{
    height: 25px;
    width: 24px;
    margin: 10px;
}

.footer .leftLinksDivCont{
    display: flex;
    flex-wrap: wrap;
}
.footer .leftLinksDiv{
    /* border: 2px solid black; */
    width: 33.3%;
}

.footer .linkHead{
    font-size: 17px;
    font-weight: bold;
}

.footer .leftLinksDiv a{
    text-decoration: none;
    color:whitesmoke;
    line-height:1.75;
}

.footer .socialLinksDiv{
    /* border: 2px solid white; */
    /* width: 200px; */
    display: flex;
    justify-content: left;
    margin-top: 20px;
}
.footer .socialLinksDiv a{
    color: white;
    font-size: 27px;
    /* margin: 20px 20px; */
    margin-right: 30px;
    margin-top: 30px;
    color: white;
    transition: all 0.5s;
}


.footer .socialLinksDiv a:hover{
    transform: rotate(360deg);
}

 .copyrightTag{
    color: white;
    text-align: center;
    background:#2d3436;
    font-size: 14px;
    padding: 5px 0px;
}


@media screen and (max-width:700px) {
    .footer{
        flex-direction: column;
        padding: 30px 20px;
        height: 500px;
        /* font-size: 12px; */
    }
    .leftFoot{
        width: 100%;
        /* border: 2px solid black; */
    }
    .footer .leftLinksDiv{
      font-size: 12px;
      margin-bottom: 40px;
    }
    .rightFoot{
        height: 47%;
    }
    .footer .leftLinksDiv:nth-child(1){
        /* border: 2px solid black; */
        width: auto;
    }
    .footer .leftLinksDiv:nth-child(2){
        /* border: 2px solid black; */
        width: auto;
    }
    .footer .leftLinksDiv:nth-child(3){
        /* border: 2px solid black; */
        width: auto;
    }
    .leftLinksDivCont{
        display: flex;
        justify-content: space-between;
    }
    
}

@media screen and (max-width:900px) and (orientation: landscape) {
    .footer{
        font-size: 12px;
    }

   
    
}