.services{
    min-height: 100vh;
    background: var(--servicesBack);
    background-size: cover;
    padding: 50px 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    /* border: 2px solid black; */
}
.services .gifContParent{
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.services .gifCont .gif{
    /* height: 200px; */
    height: 34vh;
    border-radius: 50%;
}

.services .gifCont .orangeLine{
    /* width: 150px; */
    width: 13vw;
    height: 10px;
    margin:20px 20px;
    background: var(--baseColor);
}
.services .serviceName{
    font-size: 22px;
    font-size: 2vw;
    color: white;
    font-weight: bolder;
    margin: 0px 20px;
    color:var(--headColor);
}
.services .learnMore{
    margin: 20px 20px;
    height: 20px;
    height: 3.5vh;
}

@media screen and (max-width:700px){
    .services .gifCont .gif{
        height: 140px;
    }
    .services .gifCont{
        /* border: 2px solid black !important; */
        padding-left: 5px;
        width: 170px;
    }
    .services .gifCont .orangeLine{
        margin:20px 0px 20px 10px;
    }
    .services{
        padding: 50px 0px;
    }
    .services .gifCont .orangeLine{
        width: 150px;

    }
    .services .learnMore{
        height: 20px;
    }
    .services .serviceName{
        font-size: 22px;
    }

}

@media screen and (max-width:900px) and (orientation: landscape) {
    .services{
        padding: 0;
    }
    .services .gifCont .gif{
        height: 150px;
        border-radius: 50%;
    }

    .services .gifCont .orangeLine {
        width: 146px;
    }
    
}