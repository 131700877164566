
.home{
    min-height: 100vh;
    background: var(--homeBack);
    padding-top: 25vh;
    color: white;
    background-repeat: no-repeat;
    background-size: cover;
 
}

.home .slogan{
    /* font-size: 75px; */
    font-size: 6vw;
    margin-left: 50px;
    font-weight: bold;
    position: relative;
    z-index: 1;
}

.home .slogan span{
    font-size: 6vw;
    color: var(--baseColor);
}

.home .sloganParent{
    position: relative;
}
.home .orangeBack{
    background: var(--baseColor);
    height: 3.3vw;
    width: 19.5vw;
    top: 3.5vw;
    left: 60px;
    z-index: 0;
    position: absolute;
}

.home .focusCont{
    margin-left: 50px;
    font-size:35px;
    width: 480px;
    font-weight: bold;
    margin-top: 15px;
    white-space: nowrap;
    display: flex;
    justify-content:left;
    font-size: 3vw;
}
.home .focusCont div:nth-child(1){
    color: var(--baseColor);
}

.home .mission{
    width: 32vw;
    text-align: justify;
    margin-left: 50px;
    margin-top: 5vh;;
    font-size: calc(1.5vw);
}
.home .btnDiv{
    display: flex;
    justify-content: left;
    /* margin: 25px 0 0 50px; */
    margin:5vh 0 0 50px;
    /* width: 30vw; */
    /* border: 2px solid black; */
}

.home .btnDiv .exploreBtn{
    background: var(--baseColor);
    padding:10px 30px;
    color: black;
    font-weight: bolder;
    border: 4px solid var(--baseColor);
    cursor: pointer;
    text-decoration: none;
    font-size: 1.2vw
}

.home .btnDiv .requestBtn{
    padding: 10px 30px;
    font-weight: bolder;
    border: 4px solid white;
    cursor: pointer;
    text-decoration: none;
    color: white;
    font-size: 1.2vw;
    margin-left: 50px;
}

.home .btnDiv .exploreBtn a{
    text-decoration: none;
    color: black;
}
.home .btnDiv .requestBtn a{
    text-decoration: none;
    color: white;
}

.home .quote{
    margin-top: 10vh;
    margin-left: 50px;
    font-size: 1.2vw;
    /* position: absolute; */
    /* bottom: 40px; */
}


.home .focusCont .myWordContPar{
    height: 50px;
    margin-left: 20px;
    border-radius: 5px;
  }
  .home .focusCont .myWordContPar .myWordCont{
    width: 100%;
    color: white !important;
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-weight: bolder;
    transition: all 0.9s;
    overflow: hidden;
    border-radius: 5px;
  }


  #rcc-confirm-button{
    transition: all 0.5s;
  }
  #rcc-confirm-button:hover{
   background: #2d3436 !important;
   color: white !important;
  }

@media screen and (max-width:700px) {
    .home .focusCont{
        margin-left: 20px;
        justify-content: left;
        font-size:27px;
        width: 100vw;
        /* border: 2px solid black; */
    }
    .home .slogan{
        margin-left: 20px;
    }
    .home .mission{
        margin-left: 20px;
        width: 350px;
    }

    .home .btnDiv{
        /* width: 350px; */
        margin-left: 20px
    }

    .home .quote{
       
        margin-left: 20px;
        font-size: 15px !important;
        display: inline-block;
        white-space: nowrap;
    
        /* margin: 0; */
    }
    .home .orangeBack{
        left: 35px;
    }
    .home .slogan span{
        font-size: 40px;
    }
    .home .slogan{
       
        font-size: 40px;
    }
    .home .orangeBack{
        background: var(--baseColor);
        height: 27px;
        width: 129px;
        top: 20px;
        left: 25px;

    }
    .home .focusCont {
        font-size: 25px;
    }
    .home .btnDiv{
        font-size: 13px;
    }

    .home .btnDiv{
        width: 80% !important;
    }
    .home .mission{
        width: 90% !important;
        font-size: 16px;
    }
    .home .quote{
        width: 80% !important;
        font-size: 14px !important;
        /* display: none; */
    }
    .home .btnDiv{
        width: 100%;
        /* border: 2px solid white; */
    }
    .home .btnDiv .requestBtn,.home .btnDiv .exploreBtn{
        font-size: 12px;
        padding: 10px 20px;
    }
    

   
}

@media screen and (max-width:900px) and (orientation: landscape) {
   
    .home .slogan{
        font-size: 35px;
        margin-left: 50px;
        font-weight: bold;
        position: relative;
        z-index: 1;
    }
    
    .home .slogan span{
        font-size: 35px;
        color: var(--baseColor);
    }
    
    .home .sloganParent{
        position: relative;
    }
    
    .home .orangeBack {
        background: var(--baseColor);
        height: 27px;
        left: 57px;
        position: absolute;
        top: 15px;
        width: 112px;
        z-index: 0;
    }
    
    .home .focusCont{
        margin-left: 50px;
        font-size:28px;
        width: 480px;
        font-weight: bold;
        margin-top: 15px;
        white-space: nowrap;
        display: flex;
        justify-content:left;
    }

   

  } 