.active{
    border-bottom: 4px solid var(--baseColor);
    /* padding-bottom: 8px; */
}
.navbar {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    /* box-shadow: 1px 2px 5px lightgray; */
    position: fixed;
    width: 100%;
    z-index: 1000;
    top: 0;
    /* background: white; */
}

.companyTag {
    color:black;
    cursor: pointer;
    text-decoration: none;
   
    margin-left: 40px;
    white-space:nowrap;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}
.companyTag img{
    height: 25px;
    width: 25px;
    margin-right: 20px;
}
.companyTag span{
    color: var(--baseColor);
    font-size: 1.4vw;
}

.nav-side-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    gap: 0 10px;
    width: 70vw;
    justify-content: end;
    margin-right: 35px;
    text-transform: uppercase;
    /* font-size: 16px; */
    font-size: 1.25vw;
   
}

.nav-items {
    display: flex;
    align-items: center;
    height: 80px;
}

.nav-links {
    color: black;
    text-decoration: none;
    /* padding: 8px 20px; */
    /* width: 126px; */
    margin-right: 20px;

}




.nav-links:hover {
    /* background-color: #000; */
    /* background: #f9a61a; */
    /* border-radius: 5px; */
    /* padding: 8px 20px; */
    border-bottom: 4px solid var(--baseColor);
    /* padding-bottom: 8px; */
   
}

.nav-links-button {
    display: none;
}


.dropdown-menu {
    /* width: auto; */
    position: absolute;
    top: 50px;
    list-style: none;
    text-align: start;
    min-width: 150px;
    /* width: 150px; */
    /* margin-left: -50px; */
    transition: all 0.5s;
    animation: zoom 0.5s;
    background: transparent;
    backdrop-filter: blur(5px);
    background: var(--navBackColorOld);
    color: white !important;
    
}

.dropdown-menu li{
    cursor: pointer;
    /* margin-left: 30px; */
    transition: 0.5s all;
    /* background: transparent; */
    backdrop-filter: blur(5px);
    border-radius: 5px;
    margin-left: -40px;
    /* font-size: 15px; */
    font-size: 1.2vw;
    text-transform:normal;
    color: white !important;
}

.dropdown-menu li:hover {
    /* background: #f9a61a; */
    color:  #f9a61a;
    font-weight: bold;
}
.dropdown-menu li a:hover{
    color:  #f9a61a;
    font-weight: bold;
}

.drop-menu {
    display: none;
    transition: 0.5s all;
    animation: zoom 0.5s;
}

.menu-items {
    display: grid;
    text-decoration: none;
  /* color: black;; */
  color: white;
  text-transform: none;
    padding: 5px 15px;
    text-align: left;
    transition: 0.5s all;
   

}

.menu-icon{
    display: none ;
}

/* -------------------------------------------------------------------------mobile view -------------------------------------------------------- */


@media screen and (max-width: 700px) {
    .companyTag span{
        color: var(--baseColor);
        font-size: 16px;
    }
    .nav-side-menu {
        padding: 0;
        display: flex;
        flex-direction: column;
        width: 250px;
        position: absolute;
        margin-top: 0;
      
        /* border: 2px solid #ececee; */
        opacity: 1;
        justify-content: flex-start;
        margin: 0;
        background: #fff;
        right: 0;
        top: 50px;
        opacity: 1;
        transition: all 0.5s ease;
        overflow: hidden;
        width: 0;
        background: transparent;
        /* color: white; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* background: black; */
  backdrop-filter: blur(5px);
  background: var(--navBackColorOld);
  color: white;
   /* background: rgb(3, 61, 67);; */
  /* border-radius: 5px; */
z-index: 1000000000;
        /* opacity: 0; */
    }

    .nav-side-menu.start {
        /* background: #fff;
        right: 0;
        top: 70px;
        opacity: 1;
        transition: all 0.5s ease; */
        transition: 0.5s all;
        width: 230px;
    
    }

    .nav-links {
        text-align: center;
        /* padding: 25px; */
        width: 100%;
        display: table;
        transition: all 0.5s;
        padding: 10px 20px;
        color: white !important;
        height: auto;
        font-size: 16px;
       

    }

    .nav-links:hover {
        color: #fff;
        background:transparent;
    }

    .logo {
      width: 80%;
    }

    .menu-icon {
       display: revert;
       margin-right: 16px;
       cursor: pointer;
      
    }
    .dropdown-menu li{
        font-size: 15px;
    }

    .nav-links-button {
        display: block;
        /* padding: 12px 30px; */
        margin: 25px auto;
        border-radius: 10px;
        background: #000;
        text-decoration: none;
        /* color: #fff; */
        font-size: 1rem;
    }

   

    .nav-items {
        height: auto;
    }

  
    .navLogo{
        display: none;
    }
   
  
   .companyTag{
    margin-left: 15px;
   }

   .nav-links:hover {
    border-bottom: none;
}
.nav-items{
  opacity: 0;
  transition: 0.5s all;
}

.dropdown-menu {
    /* width: auto; */
    position: relative;
    top: 20px;
    list-style: none;
    text-align: start;
    white-space: nowrap;
    /* animation: none; */
    padding: 0;
    animation: fade 0.5s;
    margin-left: -50px;
    /* width: 100%; */
}

.dropdown-menu li{
    background: transparent;
    cursor: pointer;
    margin-left: 30px;
    margin-bottom: 20px;
    transition: 0.5s all
}

}




@media screen and (max-width:900px) and (orientation: landscape) {
.nav-links{
    margin-right: 1px;
}

.nav-side-menu{
    font-size: 14px;
}

}