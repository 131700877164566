@keyframes slide-in {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0%);
    }
  }

.popCont{
    height: 200px;
    background-color: var(--wcuBack);
    backdrop-filter: blur(30px);
    width: 300px;
    position: fixed;
    bottom: 1%;
    right: 1%;
    z-index: 100;
    border-radius: 10px;
    color: white;
    transition: all 0.5s;
    animation: slide-in 1s;
    display: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    /* background: #636e72; */
}

.popTop{
    background: rgba(24, 22, 24, 0.767);
    height: 20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 19px;
    background: #f9a61a;
    background: var(--navBackColorOld);
    border-radius: 10px;
    border-bottom-left-radius:0px;
    border-bottom-right-radius:0px;
     
}
.popLogo{
    height: 100%;
    display: flex;
    align-items: center;
}
.popLogo img{
    width: 40px;
    display: none;
}
.closeIcon{
    cursor: pointer;
    color: white;
    font-size: 25px;
    transition: all 0.5s;
}

.closeIcon:hover{
    transform: scale(1.2);
}

.proMid{
    padding: 10px 20px;
    color: black;
    font-size: 16px;
    font-family: calibri;
    text-decoration: none;
    /* display: flex; */
    flex-wrap: wrap;
}
/* .proMid .proMidBtn a{
    text-decoration: none;
    color: black;
    font-size: 16px;
    font-weight: bolder;
    background: #6351ce;
    background: var(--navBackColor);
    color: white;
    padding: 8px 15px;
    border-radius: 20px;
 
} */
.proMid .proMidBtn{
    /* margin-top: 30px; */
    border-radius: 5px;
    cursor: pointer;
    text-decoration: underline;
    margin-left: 10px;
}
.proMid .proMidBtn a{
    color: black;
}
.proMidContact{
    margin-top: 20px;
    display: none;
    animation: 1s fade;
    color: #6351ce;
    color: var(--baseColor);
    font-weight: 550;
}
.proMidHead{
    margin-bottom: 20px;
    font-size: 20px;
    color: #6351ce;
    font-weight: 900;
}

.gtBtn{
    background: var(--navBackColorOld);
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    bottom: 2%;
    left: 5%;
    width: 90%;
    /* margin-left: calc(50% - 60px); */

}
.gtBtn a{
    color: white;
    text-decoration: none;

}