.contactUs{
    padding-top: 50px;
    /* min-height: 100vh; */
    width: 100vw;
    background: var(--contactBack);
    color: var(--headColor);
}

.contactUs img{
    max-width: 30px;
    max-height: 30px;
    margin-right: 10px;
}
.contactUs .imgDiv{
    display: flex;
    align-items: center;
    font-size: 20px;
    font-size: 1.6vw;
    margin-top: 20px;
    /* width: 200px; */
}
.contactCont{
    /* border: 2px solid black; */
    display: flex;
    /* height: calc(100vh - 50px); */
    justify-content: center;
    /* align-items: center; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transform: scale(0.8);
}
.contactDetails{
    width: 50%;
    /* border: 2px solid black; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: url("../images/contactBack.png");
    background-size: cover;
    color: white;
    padding-left: 10%;
    /* align-items: center; */
}
.contactCont .details{
    margin: 10px 39px;
    font-size: 1.3vw;
}

.contactUs .contactForm{
    /* border: 2px solid black; */
    width: 50%;
    height: 100%;
}

.contactForm .formHead{
    text-align: center;
    font-size: 22px;
    font-size: 2.2vw;
    font-weight: bolder;
    margin-top: 20px;
}
.contactUsForm .label{
display: flex;
width: 100%;
/* border: 2px solid black; */
margin-top: 20px;
padding-left: 20px;
white-space: nowrap;
font-size: 1.2vw;
}

.contactUsForm input{
    width: 40%;
    height: 40px;
    margin-left: 10px;
    margin-top: 10px;
    color: var(--headColor);
    padding-left: 10px;
    border: 0.5px solid black;
}

.contactForm textarea{
    width: calc(80% + 10px);
    margin-left: 10px;
    margin-top: 10px;
    height: 80px;
    resize: none;
    color: var(--headColor);
    padding: 10px;
    border: 0.5px solid black;
}

.formSubmitBtn{
    background:gray;
    padding: 10px;
    color: white;
    width: 150px;
    border: none;
    cursor: pointer;
    font-size: 18px;
    border-radius: 5px;
    margin-bottom: 50px;
    transition: all 0.5s;
    /* pointer-events: none; */
    opacity: 0.5;
    cursor:not-allowed
}

.activeBtn{
    background:#f5a688;
    pointer-events: revert;
    opacity: 1;
    cursor: pointer;
}

.buttonCont{
    width: 100%;
    display: flex;
    justify-content: center;
    /* border: 2px solid black; */
}

.contactPopUp{
    position: fixed;
    top: 20px;
    transform: scale(0);
    transition: all 0.5s;
    z-index: -1000;
   
    /* border: 2px solid black; */
}
.contactPopUp .contactUs{
    margin-left: 10vw;
  padding: 0;
  width: 80vw;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /* transform: scale(0); */
}

.contactPopUp .contactCont{
    transform: scale(1) !important;
}

.contactPopUp .contactClose{
    font-size: 42px;
    position: absolute;
    right:20px;
    cursor: pointer;
    top: 10px;
    color: gray;
    z-index: 3000;
    transition: all 0.5s;
}

.contactPopUp .contactClose:hover{
    transform: scale(1.2);
}


.captchaCont{
    /* border: 2px solid black; */
    overflow: hidden;
    padding-left: 10px;
    margin:20px 0px;
    position: relative;
    pointer-events: none;
}



@media screen and (max-width:700px) {
    .contactCont{
        flex-direction: column;
        height: auto;
        transform: scale(0.95);
    }
    .contactUs .contactForm{
        width: 100%;
    }
    .contactUs .contactDetails{
        width: 100%;
    }

    .contactPopUp{
        position: fixed;
        top: 50px;
        /* position: absolute; */
    }
    .contactPopUp .contactUs{
      margin-left: 0vw;
      width: 100vw;
    }
    .contactPopUp .contactUs .label{
        font-size: 13px;
    }


    .contactPopUp .contactDetails{
        display: none;
    }
    .contactUs .imgDiv{
        font-size: 20px;
    }
    .contactCont .details{
    font-size: 15px;
}
.contactForm .formHead{
    font-size: 22px;
}

.contactUsForm .label{
    font-size:16px;
}

}

@media screen and (max-width:900px) and (orientation: landscape) {

    .contactCont{
        height: auto;
    }
   

}

@media screen and (max-width:900px) and (orientation: landscape) {
    .contactCont{
        transform: scale(0.9);
    }
    .contactPopUp{
        position: absolute;
        font-size: 10px;
    }
}

