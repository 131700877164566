.surveyProgramming{
    min-height: 100vh;
    padding-top: 50px;
    background: var(--spBack);
    color: var(--headColor);
  
} 

.customSection,.customSection2,.mobileCompatible{
    padding: 0 150px;
    width: 100%;
}

.spCustomCont1{
    padding: 25px 50px;
    /* box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px; */
}

.knowMore a{
    font-size: 25px;
    color: white !important;
    text-decoration: none;
    border-radius: 50%;
    width: 40px;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 5px;
    border: 2px solid white;
}



.spPage1{
    padding: 0px 130px;
}

.spCont:nth-child(1){
    /* background: url("../images/back/spBack.gif"); */
    background-size: cover;
    min-height: calc(100vh - 50px);
}
.spCont .spLeft{
    width: 50%;
    position: relative;
}

.spLeft .leftBorder{
    height: 40%;
    width: 10px;
    margin-top: 2%;
    position: absolute;
    left: 45px;
}

.spLeft .spHead{
    font-size: 32px;
    font-weight: bolder;
    margin-top: 20px;
    margin-bottom: 10px;
    height: 80px;
    display: flex;
    text-align: center;
    padding-left: 10px;
    align-items: center;
    border-left:8px solid var(--quote);
    color: var(--headColor);
}
.spHead1{
    position: relative;
}
.spHead .textDiv{
    position: relative;
    font-size: 32px;
    font-weight: bolder;
    font-size: 2.2vw;
}



.spHead .textDiv div:nth-child(1) {
    background: var(--baseColor);
    height: 80%;
    left: 8px;
    bottom: -30%;
    width: 200px;
    width: 15vw;
    position: absolute;
    z-index: 0;
}

.spHead .textDiv div:nth-child(2) {
    z-index: 1;
}

.spCont .spLeft img{
    width: 65%;
}
.spCont .spRight{
    width:50%;
    display: flex;
    flex-direction: column;
}

.spLeft span:nth-of-type(1){
    background: var(--baseColor);
}
.spRight .rfd a:nth-child(1){
    text-decoration: none;
    font-size: 15px;
    position: absolute;
    right: 120px;
    margin-top: 10px; 
    color: black; 
}
.spLeft span:nth-of-type(2) a{
    text-decoration: none;
    color: black; 
    margin-left: 20px;
}

.spCont .spRight div:nth-child(1){
    background:var(--quote);
    /* color: white; */
    padding: 20px;
    font-size: 22px;
    font-size: 1.8vw;
    font-weight: bold;
    text-align: center;
    width: 90%;
    font-style: italic;
}



.spSubHead img{
    height: 50px;
    margin-right: 20px;
    z-index: 1;
}
.spSubHead{
    margin-bottom: 20px;
    /* border:2px solid black; */
    /* width: 200px; */
/*     margin-left: 70px; */
    position: relative;
    justify-content: left !important;
    font-style: italic;
    font-weight: bolder;
    justify-content: center;
    margin-top: 60px;
}

.spSubHead .orangeCircle{
    background: var(--baseColor) !important;
    width: 40px !important;
    /* height: 40px; */
    border-radius: 50%;
    position: absolute;
    left: -2%;
    top: -30%;

}

.spLast{
  font-size: 1.2vw;
}

.spSubHead a{
    text-decoration: none;
    color: black;
}


.spCont:nth-child(2){
    margin-top: 50px;
    margin-bottom: 20px;
}


.spCustomCont{
    /* min-height: 100vh; */
   padding: 25px 50px;
    margin-top: 50px;
    background: white;
    font-size: 15px;
    height: calc(90vh - 50px);
    font-size: 1.2vw;
    /* box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px; */
    /* transform: scale(0.9); */
}
.spCustomCont .content{
    width: 33.3333%;
    height: calc(100vh - 50px);
    height: 100%;
    /* border: 2px solid black; */
}
.content div{
    /* border: 1px solid black; */
    width: 100%;
    height: 50%;
}
.spCustomCont .content .gifCont{
    display: flex;
    align-items: center;
    justify-content: center;
     box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}
.spCustomCont .content .gifCont img{
    max-width: 95%;
    max-height: 95%;
}
.spCustomCont .content .textContainer div{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    background: var(--wcu);
    font-weight: 550;
    /* font-size: 22px; */
    overflow: hidden;
    
}
.content.last{
    display: flex;
    flex-direction: column-reverse;

}
.content .cube{
    height: 70% !important;
}

.content .enhance{
    height: 30% !important;
}

.spCustomCont .tiles{
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    background: linear-gradient(to right, #079bad, #00abab, #00ba9c, #2cc684, #6dd063) !important;
    color: black;
    
}

.content img{
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;; */
  /* box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px; */
  /* box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px; */
}

.textContainer .rotate{
    transform: rotate(90deg);
    /* border: 2px solid black; */
    font-weight: bolder;
}

.gifCont.sp3{
    text-align: center;
  
}
.textContainer.last{
   
}

.spCustomCont1{
    
    margin-bottom: 50px;
    height: calc(90vh - 50px);
    overflow: hidden;
}
.upperDiv{
    height: 50%;
    /* border: 2px solid black; */
    display: flex;
    /* border: 2px solid black; */
}

.gifCont.lastGifCont{
    height: 100%;
}

.upperDiv img{
    max-height: 100%;
    max-width:100%;
}

.upperDiv div{
    height: 100% !important;
    width: 100%;
    /* border: 2px solid black; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.upperDiv div:nth-child(2){
    background: var(--serviceBack);
   
}
.spCustomCont1 .lowerDiv{
   height: 50%;
    /* border: 2px solid black; */
    display: flex;
}
.spCustomCont1 .lowerDiv .gifCont:nth-child(1){
    width: 35%;
}
.spCustomCont1 .lowerDiv .gifCont:nth-child(2){
    width: 60%;
}
.spCustomCont1 .lowerDiv .gifCont img{
    max-width: 100%;
    max-height: 100%;
}
.spCustomCont1 .lowerDiv .knowMore{
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: left;
    padding-left: 50px;
    flex-direction: column;
    background-image: linear-gradient(to right, #0c0c0c, #242424, #3b3b3b, #545454, #6e6e6e);
    background: grey;
    color: white;
    /* border-radius: 8px; */
    /* box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px; */
    cursor: pointer;

}

.spCustomCont1 .listCont h3{
    padding: 0 50px;
   border-left:4px solid var(--headColor);
    padding-left: 10px;
    background:white;
    
}
.spCustomCont1 .listCont{
    padding: 0 40px;
  /*  background: var(--quote) !important; */
    width: 27%;
}

.spCustomCont1 img{
    box-shadow: rgba(0, 0, 0, 0.39) 0px 3px 12px;
}
.knowMore a{
    text-decoration: none;
    color: black;
}
.spCustomCont1 .lowerDiv .lowerList{
    width: 52%;
    background:white;
    height:100%;
}

.spCustomCont1{
    margin: 50px 0;
    font-size: 1.2vw;
}

@media screen and (max-width:700px) {
    .surveyProgramming{
        padding: 50px 20px;
    }
    .spCont{
        flex-direction: column;
    }
    .spCont .spLeft{
        width: 100%;
        padding-left: 20px;
    }
    .spCont .spLeft img{
        width: 95%;
    }
    .spHead .textDiv{
        font-size: 26px;
       
    }
    
    .spCont .spRight{
        width: 100%;
        flex-direction: column-reverse;
    }
    .spLast{
        padding-left: 20px;
        margin-bottom: 20px;
        margin-top: 40px;
        font-size: 15px;
    }

    .spCont .spRight div:nth-child(1){
        margin-bottom: 20px;
        width: 90%;
    }

    .spCustomCont{
        display: block !important;
    }

    .spCustomCont .content{
        width: 100%;
        height: 550px;
    }

    .spCustomCont1 .upperDiv{
        height: auto;
        flex-direction: column;
    }
    .spCustomCont1 .lowerDiv{
        flex-direction: column;
        margin-top: 70px;
        /* border: 2px solid black; */
        height: auto;
    }
    .spCustomCont1 .lowerDiv div{
        /* margin: 30px 0; */
        width: 100% !important;
        display: flex;
        justify-content: center;
    }

    .spCustomCont1 .lowerDiv .knowMore{
        height: 200px;
        align-items: center;
        padding: 0;

    }

    .spCustomCont1 .upperDiv div:nth-child(2){
    
        font-size: 20px;
        margin-top: 40px;
        padding: 10px;

    }
    .spCustomCont .listCont h2{
        padding-left: 20px;
    }

    .spRight .rfd a:nth-child(1){
        bottom: 0px;
    }
    .textContainer.block div:nth-child(1){
        /* font-size: 16px; */
        height: auto !important;
    }
    .textContainer.last div{
        width: auto !important;
    }
    .customSection,.customSection2,.mobileCompatible{
        padding: 0 0;
    }

   
    .spPage1{
        padding: 0;
    }

    .spCustomCont,.spCustomCont1{
        height: auto;
    }
    .spCustomCont .content.cubeCont{
        height: auto !important;
    }
    
    .spCont .spRight div:nth-child(1){
        width: 100%;
    }
    .spCustomCont1 .upperDiv .listCont{
        width: 100%;
    }

    .spCustomCont,.spCustomCont1{
        padding: 25px 15px;
        font-size: 15px;
    }
    .spCont .spRight div:nth-child(1){
        font-size: 22px;
    }
    .spCustomCont1{
    font-size: 15px;
}

.spHead .textDiv div:nth-child(1) {
    width: 60vw;
}
}

@media screen and (max-width:900px) and (orientation: landscape) {

    .customSection,.customSection2,.mobileCompatible{
        padding: 0;
        
    }
   .content .div{
    width: auto;
   }
   .content .cube img{
    transform: scale(0.5)
   }
   .content {
    font-size: 9px;
   }
    .spPage1{
        padding: 20px;
    }

    .spCustomCont1 .lowerDiv .knowMore{
        height: auto;
        align-items: center;
        padding: 0;
     margin-left: 0px;
        margin-top: 20px;

    }
    .mobileCompatible{
        padding: 20px;
    }


    .spCustomCont1 .lowerDiv .gifCont:nth-child(2) {
        width: 35%;
    }
}