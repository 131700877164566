.testimonial{
 
}

.testCont{
    height: calc(100vh - 50px);
 
    display: flex;
    color: var(--headColor);
}

.testCont .textDivCont .textDiv{
 
    width: 60%;
    position: relative;
    font-size: 14px;
    padding: 20px;
    margin-top: 20px;
    font-size: 1.2vw;
    padding-top: 10px;
}
.testCont .textDivCont .textDiv img{
width: 110%;
height: 110%;
position: absolute;
z-index: -1;
top: -5%;
left: -6%;

}
.testCont .textDivCont .textDiv .text{
    z-index: 1;
}

.testCont .leftTestCont{
   
    width: 35%;
    display: flex;
 
    align-items: center;
    flex-direction: column;
    padding-top: 10%;
    padding-left: 30px;
}

.testCont .textDivCont{
    width: 85%;
  
}
.testCont .textDiv:nth-child(2){
    margin-left: 30%;
}
.testCont .textDiv{
    margin-left: 4%;
}
.leftTestCont div:nth-of-type(1){
    /* font-size: 28px; */
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 2.5vw;
}

.leftTestCont div:nth-of-type(1) span{
    color: var(--baseColor);
}
.leftTestCont div:nth-of-type(2){
    width: 70%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    text-align: center;
    /* font-size: 18px; */
    font-size: 1.2vw;
}

.leftTestCont img{
    width: 60px;
}


@media screen and (max-width:700px) {
    .testCont{
    flex-direction: column;
    height: auto;
    }

    .testCont .leftTestCont{
        width: 100%;
        padding-left: 0px;
    }
    .testCont .textDivCont{
        width: 100%;
    }
    .testCont .textDivCont .textDiv{
        font-size: 14px;
    }
    .leftTestCont div:nth-of-type(1){
        font-size: 28px;
    }
    .leftTestCont div:nth-of-type(2){
        font-size: 18px;
    }


}

@media screen and (max-width:900px) and (orientation: landscape) {
    .testCont{
        height: auto;
    }
}