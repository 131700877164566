.team {
  min-height: 100vh;
  /* padding: 50px 30px 0 30px; */
  background: var(--teamBack);
  color: var(--headColor);
  padding: 20px 150px;
}

.team .teamCont {
  padding-top: 50px;
}
.team .teamMiddle img {
  /* height:120px;
    width: 120px; */
  border-radius: 50%;
  filter: grayscale(100%);
  transition: all 0.5s;
  cursor: pointer;
  height: 10vw;
  width: 10vw;
}
.team .teamMiddle img:hover {
  filter: revert;
  transform: scale(1.2);
}
.teamTop {
  text-align: center;
  height: 45vh;
  font-size: 32px;
  font-weight: bold;
  position: relative;
  display: flex;
  justify-content: center;
}
.teamTop img {
  position: absolute;
  top: 0;
  opacity: 0.2 !important;
  width: 100%;
  max-height: 100%;
  /* filter: grayscale(100%); */
}

.teamTop div {
  /* z-index: 1; */
  margin-top: 10px;
  height: 40px;
  font-weight: bolder;
  /* background: white; */
}

.teamMiddle {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  font-size: 1.2vw;
}
.teamMiddle .teamCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -87px;
}

.teamMiddle .teamCard div:nth-of-type(1) {
  font-weight: bolder;
  margin-top: 15px;
}

.teamBottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  /* font-size: 15px; */
  font-size: 1.2vw;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}
.teamBottom div:nth-of-type(1) {
  width: 90%;
  text-align: center;
  /* font-weight: bolder; */
  margin-top: 20px;
}

.teamBottom div:nth-of-type(2) {
  width: 90%;
  text-align: center;
  /* font-weight: bolder; */
  margin-top: 20px;
}

.team .textDiv {
  position: relative;
  font-size: 2.5vw;
  font-weight: bolder;
  white-space: nowrap;
  z-index: 1;
}
.team .textDiv div:nth-child(1) {
  background: var(--baseColor);
  height: 70%;
  left: -5px;
  width: 13vw;
  top: 40%;
  position: absolute;
  z-index: 0;
}

.team .textDiv div:nth-child(2) {
  z-index: 1;
}

@media screen and (max-width: 700px) {
  .team {
    padding: 150px 0px 20px 0px;
  }
  .team .teamMiddle img {
    border-radius: 50%;
    filter: grayscale(100%);
    transition: all 0.5s;
    cursor: pointer;
    height: 120px;
    width: 120px;
  }
  .teamTop {
    height: 200px;
  }
  .teamTop img {
    transform: scale(2) !important;
  }

  .teamMiddle .teamCard {
    margin-top: 0px;
  }
  .teamTop div {
    margin-top: -75px;
  }
  .teamMiddle {
    margin-top: -110px;
  }
  .team .teamMiddle img:hover {
    filter: revert;
    transform: scale(1);
  }

  .team .textDiv div:nth-child(1) {
    bottom: 32px;
    height: 40px;
  }

  .team .teamCont {
    padding-top: 50px;
    transform: scale(1);
  }
  .teamBottom,
  .teamMiddle {
    font-size: 16px;
  }
  .team .textDiv {
    font-size: 32px;
  }
  .team .textDiv div:nth-child(1) {
    width: 180px;
    height: 30px;
    top: 55px;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .team {
    padding: 0;
  }
  .teamTop {
    overflow: hidden;
    /* height: 140px; */
  }

  .teamTop img {
    max-height: revert;
  }

  .teamMiddle .teamCard {
    font-size: 12px;
    margin-top: -50px;
  }
  .teamMiddle .teamCard img {
    height: 100px;
    width: 100px;
  }

  .team .textDiv div:nth-child(1) {
    top: 20%;
  }

}
