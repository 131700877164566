.proManage{
    min-height: 100vh;
    background: var(--proManBack);
    padding-top: 50px;
    color: var(--headColor);
} 
.proManage .cardCont{
    height: calc(100vh - 150px);
    /* border: 2px solid black; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 170px;
}
.proManage .cardCont .parentCard{
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.proManage .card .fa-plus{
    /* border: 1px solid black; */
    border-radius: 50%;
    padding: 2px 3px;
    position: absolute;
    top: 7%;
    right:7%;
    color: #2cc684;
    background: white;
}

.proManage .cardCont .card{
    height: 46%;
    width: 200px;
    width: 16vw;
    position: relative;
    /* border: 2px solid black; */
    display: flex;
    justify-content: center;
    align-items: end;
    padding: 20px;
    border-radius: 10px;
    font-size: 20px;
}

.pmHead{
    margin: 30px 0;
    padding-left: 160px;
}
.pmHead .textDiv{
    position: relative;
    font-size: 32px;
    font-weight: bolder;
    color: #5d676a;
    font-size: 2.5vw;
}

.pmHead .textDiv div:nth-child(1) {
    background: var(--baseColor);
    /* height: 30px; */
    left: -5px;
    /* bottom: -10px; */
    /* width: 210px; */
    position: absolute;
    z-index: 0;
    width: 20vw;
    height: 80%;
    bottom: -30%;

}

.pmHead .textDiv div:nth-child(2) {
    z-index: 1;
}
.proManage .card.card1{
    height: 30%;
    align-items: center;
    background: #e2e2e2;
    position: relative;
}
.proManage .card.card1 img{
    position:absolute;
    top: -20%;
    width: 50px;
}
.proManage .card.card2{
    height: 60%;
    flex-direction: column;
    justify-content: end;
    background-image: linear-gradient(to right, #0c0c0c, #242424, #3b3b3b, #545454, #6e6e6e);
    color: white; 
}
.proManage .card.card2 div{
    width: 100%;
}
.proManage .card.card2 div:nth-child(2){
    font-weight: bolder;
}
.proManage .card.card3{
    background-image: linear-gradient(to right, #079bad, #00abab, #00ba9c, #2cc684, #6dd063);
    color: white;
}
.proManage .card.card4{
    background: #e2e2e2;

}
.proManage .card.card5{
   background: #e2e2e2;
   
}
.proManage .card.card6{
    background-image: linear-gradient(to right, #079bad, #00abab, #00ba9c, #2cc684, #6dd063);
    color: white;
}
.proManage .card.card7{
   height: 30%;
   background-image: linear-gradient(to right, #0c0c0c, #242424, #3b3b3b, #545454, #6e6e6e);
   color: white;

}
.proManage .card.card8{
   height: 60%;
   position: relative;
   padding: 0;
   overflow: hidden;
   /* display: none; */
}
.proManage .card.card8 img{
    width: 100%;
    height: 100%;
    position: absolute;
}

.card8Text{
    z-index: 1;
    padding: 20px 30px;
   color: white;
   font-size: 20px;
   font-weight: bolder;
}
.card.card2 .fa-arrow-right{
    border: 2px solid white;
    border-radius: 50%;
    padding: 6px;
    font-size: 35px;
}

.card.card2 .arrowCont{
    width: 100%;
    margin-top: 10px;
}


.proManage .pmCont{
    padding: 50px 150px;
}
.pmSecondPage .proHead{
    width: 100%;
    /* border: 2px solid black; */
    font-size: 32px;
    color: var(--headColor);
    font-weight: bolder; 
    margin-bottom: 20px;
}
.pmSecondPage{
    min-height: 100vh;
    /* min-height: 1300px; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    height: auto;
}

.pmSecondPage img{
    max-width: 100%;
    max-height: 100%;
}

.pmSecondPage .pmCard{
    width: 300px;
    height: 380px;
    font-size: 1.2vw;
    /* height: auto; */
}
.pmSecondPage .pmCard .headImage{
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30vh;
    /* border: 2px solid black; */
}
.pmSecondPage .pmCard .head{
    border-left: 5px solid var(--headColor);
    padding-left: 10px;
    color: var(--baseColor);
    margin: 20px 0;
    /* border: 2px solid black; */
    text-align: left;
    /* width: auto; */
}
.pmSecondPage .pmCard .text{
    font-size:1vw ;
}



@media screen and (max-width:700px) {
    .proManage .cardCont{
        flex-direction: column;
        height: auto;
    }

    .proManage .cardCont .parentCard{
        height: 300px;
        margin-top: 50px;
    }
    .proManage .cardCont .parentCard .card{
        width: 80vw;
    } 

    .proManage .parentCard.lastCard{
        height: 500px;
    }
    .proManage .parentCard.lastCard .card.card7{
        height: 20%
    }
    .proManage .parentCard.lastCard .card.card8{
        height: 75%;
    }

    .pmSecondPage .pmCardCont{
        flex-direction: column;
    }
    .pmSecondPage .proHead{
        margin:0;
        text-align: left;
        font-size: 20px;
    }
    .proManage .pmCont{
        padding: 50px 20px;
    }

    .pmHead{
        margin: 30px 20px;
        padding-left: 10px;
    }
    .pmHead .textDiv{
        font-size: 25px;
    }
    
    .pmHead .textDiv div:nth-child(1) {
        background: var(--baseColor);
        height: 20px;
        left: -5px;
        /* bottom: -15px; */
        top: 13px;
        width: 165px;
        position: absolute;
        z-index: 0;
    }

    .pmSecondPage .pmCard{
    width: 300px;
    height: 380px;
    font-size: 16px;
    /* height: auto; */
}

.pmSecondPage .pmCard .text{
    font-size: 13px;
}
    .pmSecondPage .pmCard .headImage{
        height: 200px;
    }
}


@media screen and (max-width:900px) and (orientation: landscape) {
    .proManage .pmCont{
        padding: 50px 30px;
    }
    .proManage .cardCont .card{
        font-size: 15px;
    }
    .proManage .cardCont .card.card1{
        padding-top: 50px;
    }
    .proManage .cardCont .card{
        width: 180px;
        margin-right: 10px;
    }
    .pmHead{
        margin: 30px 20px;
        padding-left: 10px;
    }
    .proManage .cardCont{
        height: calc(100vh);
    }

   
}