.aboutUs{
    min-height: 100vh;
    background: var(--aboutUsBack);
    padding: 50px 100px;
    color: var(--headColor);
    /* border: 2px solid black; */
    text-align:justify;

    -moz-text-size-adjust: none !important;
    -webkit-text-size-adjust: none !important;
    text-size-adjust: none !important;
}

.valuesCont{
    transform: scale(0.95);
  }
.aboutUs .aboutUsHeadingParent{
    margin: 50px 50px;
    display: flex;
    justify-content: left;
}

.aboutUs .textDiv{
    position: relative;
    /* font-size: 28px; */
    font-weight: bolder;
    color: #5d676a;
    font-size: 2vw;
}

.aboutUs .textDiv div:nth-child(1) {
    background: var(--baseColor);
    height: 80%;
    left: -5px;
    bottom: -30%;
    width: 15vw;
    position: absolute;
    z-index: 0;
}

.aboutUs .textDiv div:nth-child(2) {
    z-index: 1;
}



.aboutUsCont .aboutUsLeft{
    width: 50%;
}
.aboutUsCont .aboutUsLeft:nth-child(1){
    width: auto;
}
.aboutUsCont .aboutUsRight{
    width: 50%;
}

.aboutUsCont .content{
    padding: 10px 25px;
    /* font-size:16px; */
    font-size: 1.2vw;
    

    /* text-align: justify; */
    /* hyphens: auto; */
}
.aboutUsLeft{
    padding: 50px;
}
.aboutUsLeft img{
   
    border-radius: 10px;
}

.textInGif{
    position: absolute;
    bottom: 8%;
    left: 0;
    background-color: var(--quote);
    color: white;
    font-size: 32px;
    width: 70%;
    padding: 2%;
    text-align: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background:burlywood;
}

.aboutUs .smallText{
    font-weight: bold;
    font-style: italic;
    padding: 30px;
}
 
.values1Img{
    width: 100%;
}

.valuesBlueDiv{
    background:var(--quote);
    color: white;
    padding: 20px 30px;
    text-align: justify;
    hyphens: auto;
    width: 95%;
    margin-left: 2.5%;
    background:burlywood;   
    font-size: 1.2vw;
}
.values2Img{
    margin-left: 2.5%;
    width: 95% !important;
    border-radius:0px !important;
}
.pcCont{
    width: 80%;
    margin-left: 10%;
}
.aboutUsImgDiv{
    /* border: 2px solid black; */
}
.aboutUsImgDiv img{
    max-width: 100%;
    max-height: 100%;
    width: 30vw;
}

@media screen and (max-width:700px) {
    .aboutUsCont{
        flex-direction: column-reverse;
    }
    .aboutUs .textDiv{
        font-size: 25px;
    }
    .aboutUs .textDiv div:nth-child(1) {
        width: 33vw;
    }
    .aboutUsCont.valuesCont{
        flex-direction: column;
    }
    .aboutUsCont .aboutUsLeft{
        width: 100%;
    }

    .aboutUsCont .aboutUsRight{
        width: 100%;
    }
    .aboutUsLeft{
        padding: 0;
    }
    .aboutUsLeft img{
        width:90%;
        border-radius:0 !important;
    }

    .textInGif{
        /* padding: 30px; */
        font-size: 22px;
    }
    .aboutUs{
        padding: 0;
    }
    .values1Img{
        width: 100%;
    }
    .valuesCont{
        transform: scale(1);
      }
      .aboutUs .smallText{
        padding: 0px;
        margin-left: 20px;
    }

    .aboutUsImgDiv{
        margin-left: 30px;
    }
    .aboutUsHeading .valuesHead{
        font-size: 18px ;
    }
    .textDiv.valuesHead div:nth-child(1){
        top: 10px ;
        height: 20px;
        width: 153px;
    }
    .aboutUsCont .content,.valuesBlueDiv{
        font-size: 16px;
    }
   
}


@media screen and (max-width:900px) and (orientation: landscape) {

    .aboutUs{
        padding: 0;
    }
    .textInGif{
        padding: 0;
        font-size: 22px;
    }
    .valuesHead{
        font-size:20px !important;
    }
    .valuesHead div:nth-child(1){
        top: 9px;
        height: 20px !important;
    }

    .aboutUs .smallText{
        padding: 0;
    }
   

}